import { Alert, IconButton, Typography } from '@mui/joy';
import { useTranslation } from 'react-i18next';
import { SVGAlertSolid, SVGClose } from 'icons';

const WARNING_COLOUR = 'warning';
const ICON_MARGIN = '0.125rem';

export const Warning = ({ title: propsTitle, message, onClose, id }) => {
  const { t } = useTranslation();

  const title = t(propsTitle);

  return (
    <Alert
      sx={{
        mt: 2,
        alignItems: 'flex-start',
      }}
      slotProps={{ root: { id, 'data-component': id } }}
      startDecorator={(
        <SVGAlertSolid
          size={16}
          color={WARNING_COLOUR}
          ariaHidden={'true'}
          style={{ marginTop: ICON_MARGIN }}
        />
      )}
      endDecorator={(
        <>
          {onClose && (
            <IconButton
              onClick={onClose}
              aria-label={'close'}
              size={'lg'}
              sx={{ padding: 0, minHeight: 16 }}
            >
              <SVGClose size={16} style={{ marginTop: ICON_MARGIN }} />
            </IconButton>
          )}
        </>
      )}
      severity={WARNING_COLOUR}
      color={WARNING_COLOUR}
      key={title}
      onClose={onClose}
    >
      <div>
        <Typography
          level={'body-sm'}
          color={WARNING_COLOUR}
          sx={{ fontWeight: 500 }}
        >
          {title}
        </Typography>
        {message && (
          <Typography
            level={'body-sm'}
            color={WARNING_COLOUR}
          >
            {t(message)}
          </Typography>
        )}
      </div>
    </Alert>
  );
};
